<template>
  <!-- 交易企业 -->
  <div class="trading-enterprise-page-box">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      :form-item-arr="formItemArr"
    />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <!-- v-isPermission="'transactionData_tradingEnterprise_add'" -->
        <el-button v-isPermission="'transactionData_tradingEnterprise_add'" size="small" type="primary" @click="add">
          新增企业
        </el-button>
      </div>
      <!-- v-isPermission="'transactionData_tradingEnterprise_view'" -->
      <div v-isPermission="'transactionData_tradingEnterprise_view'">
        <Table
          :operation-button="operationButton"
          :item-data="itemData"
          :list-data="listData"
        />
        <!-- 分页 -->
        <Pagination
          :params="formInline"
          :total="total"
          :in-article="listData.length"
          :get-data-list="getdata"
        />
      </div>
    </div>
    <!-- 提货记录弹出窗 -->
    <el-dialog :title="title" width="40%" :visible.sync="dialogVisible">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        label-width="140px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="企业名称"
          :rules="differentLengths(2, 50)"
          prop="cmpName"
        >
          <el-input v-model="ruleForm.cmpName" placeholder="请输入" />
        </el-form-item>
        <el-form-item
          label="统一社会信用代码"
          :rules="[...rules.unicode]"
          prop="cmpUnicode"
        >
          <el-input v-model="ruleForm.cmpUnicode" placeholder="请输入" />
        </el-form-item>
        <el-form-item
          label="所属地区"
          :rules="[...rules.selectiveType]"
          prop="address"
        >
          <el-cascader
            ref="cascader"
            v-model="ruleForm.address"
            :props="{ label: 'name', value: 'code', children: 'children' }"
            :options="allCityOption"
            @change="getCity"
          />
        </el-form-item>
        <el-form-item
          label="详细地址"
          :rules="differentLengths(1, 30)"
          prop="cmpAddr"
        >
          <el-input v-model="ruleForm.cmpAddr" placeholder="请输入" />
        </el-form-item>
        <el-form-item
          label="法人"
          :rules="differentLengths(1, 15)"
          prop="legalPersonName"
        >
          <el-input v-model="ruleForm.legalPersonName" placeholder="请输入" />
        </el-form-item>
      </el-form>
      <div class="button-assembly-box">
        <el-button size="small" @click="dialogVisible = false">
          取消
        </el-button>
        <el-button size="small" type="primary" @click="submit">
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
import { getSelectAreaTree } from '@/api/login'
import { rules, differentLengths } from '@/utils/matchFormRegex.js'
import {
  pageTransactionCmpInfo,
  addTransactionCmpInfo,
  updateTransactionCmpInfo,
  deleteTransactionCmpInfo
} from '@/api/transactionData.js'
export default {
  components: {
    FormSearch,
    Table,
    Pagination
  },
  data() {
    return {
      differentLengths,
      rules,
      ruleForm: {},
      dialogVisible: false,
      title: '新增交易企业',
      formItemArr: [
        { type: 'input', label: '企业名称', value: 'cmpName' },
        { type: 'input', label: '法人', value: 'legalPersonName' }
      ],
      operationButton: [
        {
          permission: 'transactionData_tradingEnterprise_edit',
          bType: 'primary',
          label: '编辑',
          handleEvent: this.edit
        },
        {
          permission: 'transactionData_tradingEnterprise_delete',
          bType: 'primary',
          label: '删除',
          handleEvent: this.deleteData
        }
      ],
      itemData: [
        // 表格
        { label: '企业名称', prop: 'cmpName', width: 180 },
        { label: '统一社会信用代码', prop: 'cmpUnicode', width: 180 },
        { label: '企业地址', prop: 'detailedAddress', width: 180 },
        { label: '法人', prop: 'legalPersonName', width: 180 },
        { label: '创建人', prop: 'createUser', width: 180 },
        { label: '创建时间', prop: 'createTime', width: 180 }
      ],
      listData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      allCityOption: [],
      total: 0
    }
  },
  mounted() {
    getSelectAreaTree('00', (res) => {
      this.allCityOption = [...res.data]
    })
  },
  methods: {
    getCity(e) {
      if (e && e.length === 3) {
        const nameArr = this.$refs['cascader'].getCheckedNodes()[0].pathLabels
        this.ruleForm.provinceCode = e[0]
        this.ruleForm.cityCode = e[1]
        this.ruleForm.countryCode = e[2]
        this.ruleForm.provinceName = nameArr[0]
        this.ruleForm.cityName = nameArr[1]
        this.ruleForm.countryName = nameArr[2]
      }
    },
    submit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          const api = this.ruleForm.id
            ? updateTransactionCmpInfo
            : addTransactionCmpInfo
          api(this.ruleForm, () => {
            this.dialogVisible = false
            this.$message.success('成功')
            this.getdata(this.ruleForm.id)
          })
          return
        }
        this.$message.error('请完善必填项')
      })
    },
    // 编辑
    edit(row) {
      this.dialogVisible = true
      this.ruleForm = { ...row }
      this.title = '编辑交易企业'
      this.ruleForm.address = [row.provinceCode, row.cityCode, row.countryCode]
    },
    // 新增
    add() {
      this.dialogVisible = true
      this.ruleForm = {
        cmpName: '',
        cmpAddr: '',
        cmpUnicode: '',
        address: [],
        legalPersonName: ''
      }
      this.title = '新增交易企业'
      this.$nextTick(() => {
        this.$refs['ruleForm'].resetFields()
      })
    },
    // 查看详情
    deleteData({ id }) {
      this.$confirm('确认将该交易企业删除吗?', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        deleteTransactionCmpInfo(id, () => {
          this.$message.success('成功')
          this.getdata()
        })
      }).catch(() => {})
    },
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      } else if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      }
      pageTransactionCmpInfo(this.formInline, (res) => {
        res.data.pageData.forEach(item => {
          item.detailedAddress = item.provinceName + item.cityName + item.countryName + item.cmpAddr
        })
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.trading-enterprise-page-box {
  width: 100%;
  .button-assembly-box {
    text-align: center;
    margin: 24px 0 2px;
  }
  .tip-box {
    padding: 24px 0;
    font-size: 14px;
    color: #666;
    i {
      color: $assistColor4;
      font-size: 16px;
    }
  }
}
</style>
